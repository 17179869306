import './assets/css/reset.scss'
import './assets/css/element.scss'
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import directiveDialog from '@/directive/dialogDrag'
import store from './config/store'
import router from './config/router'
import filters from './util/filter'
import api from './util/api'
import help from './util/help'
import variable from './util/variable'

// 组件
import MyFooter from '@/components/MyFooter';
import MyHeader from '@/components/MyHeader';
import MyMenu from '@/components/MyMenu';
import HeadImg from '@/components/HeadImg';

Vue.config.productionTip = false

Vue.use(ElementUI, {size: 'mini', zIndex: 300})
Vue.component('MyFooter', MyFooter);
Vue.component('HeadImg', HeadImg);
Vue.component('MyHeader', MyHeader);
Vue.component('MyMenu', MyMenu);

Vue.directive(directiveDialog.name, directiveDialog);

Vue.prototype.$api = api
Vue.prototype.$help = help
Vue.prototype.$variable = variable

Vue.prototype.goSearchCom = (com_name) => {
  const { href } = router.resolve({
    path: '/drug',
    query: {
      txt: com_name,
    }
  });
  window.open(href, "_blank");
}
Vue.prototype.goSearchComp = (comp_name) => {
  const { href } = router.resolve({
    path: '/corp',
    query: {
      txt: comp_name,
    }
  });
  window.open(href, "_blank");
}

Vue.prototype.getImg = function(key){
  let img = key || ''
  if (img.indexOf('*##*')> -1) {
    img = img.split('*##*')[0]
  }
  return key ? `/horse/common/sys/attachment/viewAttachmentStream?attachmentId=${img}` : '';
}

// filter
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
