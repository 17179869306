<style lang="scss">
    @import "~@/assets/css/var";
    .mashu {
        flex-direction: column;
        display: unset;
        &-header { 
            position: sticky; 
            top: 0px;;
            display: flex;
            align-items: center;
            height: 60px;
            box-shadow: 0 5px 18px 0 rgba(122, 145, 219, 0.3);
            padding: 0 20px;
            background-color: #fff;
            z-index: 10;
            /*overflow: hidden;*/
            .logo {
                width: 100px;
            }
            .menu {
                position: relative;
                margin-left: 24px;
                width: 90px;
                font-size: 0;
                .hot {
                    position: absolute;
                    width: 30px;
                    top: 12px;
                    right: -12px;
                }
            }

            &__menu {
                display: flex;
                flex: 1;
                overflow: hidden;
                flex-wrap: nowrap;
                .el-menu {
                    display: flex;
                    margin-left: 24px;
                    border: 0;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                .el-submenu {
                    padding: 0 17px !important;
                    border: 0 !important;
                    .el-submenu__title {
                        padding: 0;
                        border: 0;
                    }
                    &.is-opened, &:hover  {
                        color: #fff !important;
                        .el-submenu__title {
                            color: #fff;
                            background: transparent;
                        }
                    }
                }
                .el-submenu__icon-arrow {
                    display: none;
                }
                .recommend {
                    position: absolute;
                    top: 12px;
                    right: -20px;
                    font-size: 12px;
                    font-style: normal;
                    color: #fff;
                    background: $red;
                    width: 18px;
                    line-height: 18px;
                    text-align: center;
                    border-radius: 4px;
                }
                .hot {
                    position: absolute;
                    top: 12px;
                    right: -13px;
                    width: 30px;
                }
            }

            &__user {
                padding-left: 40px;
                .el-link {
                    font-weight: normal;
                }
            }
        }
        &-body {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        .permissions{
            background-color: rgba(221, 221, 221, 1);
            text-align: center;
            color: #696969;
            font-size: 12px;
            line-height: 28px;
        }
    }
</style>


<template>
    <el-container class="mashu">
        <my-header></my-header>
        <div class="taoshu-body">
            <div class="taoshu-body">
                <router-view></router-view>
            </div>
        </div>
        <my-footer></my-footer>
        <div class="permissions">
            <div class="company">
                版权所有 © 江西红骑马术有限公司
            </div>
        </div>
    </el-container>
</template>

<script>
    export default {
        data() {
            return {
                loaded: false,
            }
        },
        created() {

        },
        methods: {
         
        },
    }
</script>