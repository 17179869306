<template>
    <div class="top-content">
        <div class="tab-top">
            <div class="left">
                <div class="horse" :class="{'active': bool && active === i}" v-for="(item, i) in titleList" :key="i" @click="changeTab(i)">
                    <span class="title-name" ref="title" v-if="item.name">{{item.name}}</span>
                    <span class="line" v-show="bool && active === i"></span>
                </div>
            </div>
            <div class="right">
                <div class="address">
                    <i class="el-icon-location"></i>
                    <span>您现在的位置：</span>
                </div>
                <el-breadcrumb>
                    <el-breadcrumb-item v-for="(item, i) in menuList" :key="i" :to="{ path: item.path ? item.path : '' }">{{item.name}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        titleList: {
            type: Array,
            default: ()=>{
                return []
            }
        },
        menuList: {
            type: Array,
            default: ()=>{
                return []
            }
        },
    },
    data() {
        return {
            active: 0,
            bool: false
        }
    },
    methods:{
        changeTab(i) {
            this.active = i
            this.$emit('changeTab', i)
            
        },
        setValue(value){
           this.bool = value
        },
        setActive(active) {
            this.active = active
        }
    },
    mounted() {
        this.$nextTick(()=>{
            console.log(this.$refs.title) 
        })
        
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .top-content{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 3px #ccc;
        .tab-top{
            display: flex;
            justify-content: space-between;
            padding: 30px 0;
            //line-height: 80px; 
            width: 1200px;
            .left, .right{
                display: flex;
                align-items: center;
            }
            .left{
                font-size: 17px;
                .horse{
                    position: relative;
                    span{
                        padding: 0 10px;
                        border-right: 1px solid #666;
                        font-size: 14px;
                        &:hover{
                            color: #60a9d7;
                            cursor: pointer;
                        }
                    }
                    &:last-child span{
                        border: 0;
                    }
                    .line{
                        display: inline-block;
                        width: 50px;
                        height: 2px;
                        background-color: #60a9d7;
                        position: absolute;
                        bottom: -32px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .active{
                    color: #60a9d7;
                }
            }
            .right{
                font-size: 14px;
                .address{
                    display: flex;
                    align-items: center;
                    i{
                        color: $red;
                        padding-right: 5px;
                        font-size: 20px;
                    }
                }
                .el-breadcrumb{
                    &:last-child span{
                        &:last-child span{
                            color: #65beba !important;
                        }
                        
                    }
                }
            }
        }
    }

</style>