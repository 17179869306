import help from './help';
const newYears = ['2015', '2016', '2017', '2018', '2019', '2020'];

export default {
    ERROR_MSG: '请先选择有效记录，再执行该操作。',

    basePageSize: 20,

    searchType: {
        'LIKE': 0,
        'SELECT': 1,
        'NUM': 2,
        'DATE': 3,
    },

    tokenInvalid: [401],

    // 会员类型
    member: {
        temp: '0330402af5fd46d192c18ac1629530d0',
        visit: 'd726082240ac4c29acefcc10e1202b8b',
        vip: 'bd0cde66e4964a9db954c5ea453566cb',
    },

    // 首页三种类型
    indexType: {
        DRUG: 1,
        CORP: 2,
        ASSIST: 3,
    },
    indexColumn: {
        drug(param) {
            return help.joinObj({
                field_name: 'com_name',
                name: '药品通用名',
                width: 180,
                list_query_view: '是',
                list_query_model: 0,
                list_view: '是',
            }, param)
        },
        corp() {
            return {
                field_name: 'comp_name',
                name: '企业',
                width: 180,
                list_query_view: '是',
                list_query_model: 0,
                list_view: '是',
            }
        }
    },

    // new页面上，用到的年份
    newYears,
    newQuarter: (() => {
        const arr = [];
        newYears.forEach(item => {
            arr.push(`${item}Q1`);
            arr.push(`${item}Q2`);
            arr.push(`${item}Q3`);
            arr.push(`${item}Q4`);
        })
        return arr;
    })(),
    newQuarterLowercase: (() => {
        const arr = [];
        newYears.forEach(item => {
            arr.push(`${item}_q1`);
            arr.push(`${item}_q2`);
            arr.push(`${item}_q3`);
            arr.push(`${item}_q4`);
        })
        return arr;
    })(),
}