<template>
    <div class="head-img">
        <el-image class="img" :src="imgUrl" fit="cover"></el-image>
        <div class="desc">{{desc}}</div>
    </div>
</template>

<script>
export default {
    props:{
        desc: {
            type: String,
            default: ''
        },
        imgUrl: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .head-img{
        position: relative;
        .desc{
            position: absolute;
            top: 50%;
            right: 200px;
            color: #fff;
            font-size: 30px;
            background-color: rgba(8, 7, 13, 0.5);
            padding: 10px 30px;
            border-radius: 19px;
            &:hover{
                cursor: pointer;
            }
            // font-weight: bold;
        }
        .img{
            height: 250px;
            width: 100%;
        }
    }

</style>