import Vue from 'vue';
import Vuex from 'vuex';
import ElementUI from 'element-ui'
import api from '@/util/api'
import variable from '@/util/variable'

Vue.use(Vuex);

const config = new Vuex.Store({
    state: {
        loading: {},
        titleRefer: {},
        user: {},
        userRoot: {},
        htmlView: {
            show: false,
            content: '',
        },
    },
    mutations: {
        // region 加载
        loading(state, target) {
            target = target ? target : 'body';
            state.loading = ElementUI.Loading.service({
                lock: true,
                target: target
            })
        },
        loaded(state) {
            state.loading.close && state.loading.close();
        },
        // endregion

        setTitleRefer(state, param) {
            state.titleRefer = param
        },

        showHtml(state, content) {
            state.htmlView.show = !!content
            state.htmlView.content = content || ''
        },

        // region 弹窗
        error(state, msg) {
            ElementUI.Message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: msg,
                type: 'error',
                duration: 5000,
            });
        },
        success(state, msg) {
            ElementUI.Message({
                showClose: true,
                message: msg,
                type: 'success'
            });
        },
        // endregion
    },
    actions: {
        getUser({state}, flag) {
            if (state.user.id && !flag) {
                return new Promise(res => {
                    res(state.userRoot)
                })
            } else {
                return api.get('info')
                    .then(res => {
                        if (res.data.user.role_id === variable.member.temp) {
                            res.data.user.role_name = '临时会员'
                            res.data.user.isTemp = true
                        } else if (res.data.user.role_id === variable.member.vip) {
                            res.data.user.role_name = 'VIP会员'
                            res.data.user.isVip = true
                        } else if (res.data.user.role_id === variable.member.visit) {
                            res.data.user.role_name = '游客'
                        }
                        state.user = res.data.user
                        state.userRoot = res.data
                        return res.data
                    })
            }
        },
    }
})

export default config