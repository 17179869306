import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/pages/home/Index'
// import Middle from '@/components/Middle'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

function loadComponent(url) {
    return () => import(`@/pages/${url}`)
}

const routerIndex = [
    {
        path: '/',
        name: 'index',
        component: Index,
    },
    {
        path: '/horse',
        name: 'horse',
        component: loadComponent('horse/Index'),
    },
    {
        path: '/about',
        name: 'about',
        component: loadComponent('about/Index'),
    },
    {
        path: '/coach',
        name: 'coach',
        component: loadComponent('coach/Index'),
    },
    {
        path: '/course',
        name: 'course',
        component: loadComponent('course/Index'),
    },
    {
        path: '/news',
        name: 'news',
        component: loadComponent('news/Index'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: loadComponent('contact/Index'),
    },
]
const horse = [
    {
        path: '/horse/detail',
        name: 'horsedetail',
        component: loadComponent('horse/detail'),
    },
]

const news = [
    {
        path: '/news/detail',
        name: 'newsdetail',
        component: loadComponent('news/detail'),
    },
]

const coach = [
    {
        path: '/coach/detail',
        name: 'coachdetail',
        component: loadComponent('coach/detail'),
    },
]

const config = new VueRouter({
    routes: [
        ...routerIndex,
        ...horse,
        ...news,
        ...coach
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition && to.meta.keepAlive) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

export default config