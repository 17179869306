import axios from 'axios';
import variable from '@/util/variable';
import help from '@/util/help';
import store from '@/config/store';

// const host = process.env.NODE_ENV === 'production' ? '/api/web/' : '/api/web/'
let myAxios = axios.create({
    baseURL: '/horse',
    responseType: 'json',
    withCredentials: false,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Accept": "application/prs.taoshu.v1+json",
    },
})

myAxios.interceptors.request.use(function (config) {
    config.headers['H-TOKEN'] = 'blue_data'
    config.headers['Authorization'] = help.token.get() || ''
    config.headers['Cache-Control'] = 'no-cache'
    return config;
}, function (error) {
    return Promise.reject(error);
})

function doError(res, reject, isBubbling) {
    if (res && res.data) {
        if (variable.tokenInvalid.includes(res.data.status)) {
            help.token.remove()
            store.commit('error', '登录信息失效')
        } else {
            !isBubbling && store.commit('error', res.data.msg)
        }
    }
    // else {
    //     !isBubbling && store.commit('error', '服务器内部错误')
    // }

    reject(res.data)
}

function requestFunc(func, url, params, headers = {}, isBubbling = false) {
    return new Promise((resolve, reject) => {
        let param1 = {},
            param2 = {};
        if (func === 'get') {
            param1 = {params, headers};
        } else {
            param1 = params;
            param2 = {headers}
        }
        myAxios[func](url, param1, param2)
            .then(res => {
                resolve(res.data)
                // if (res.data.status === variable.successCode) {
                //     resolve(res.data.data, res.data)
                // } else {
                //     doError(res, reject, isBubbling)
                // }
            })
            .catch(res => {
                doError({
                    data: {
                        status: res.response.data.status_code,
                        msg: res.response.data.message,
                    }
                }, reject, isBubbling)
            })
    })
}

export default {
    service: myAxios,
    axios,
    get(url, params, headers) {
        return requestFunc('get', url, params, headers)
    },
    getSelf(url, params, headers = {}) {
        return this.get(url, params, headers, true)
    },
    post(url, params = {}, headers = {}) {
        return requestFunc('post', url, params, headers)
    },
    put(url, params = {}, headers = {}) {
        return requestFunc('put', url, params, headers)
    },
    patch(url, params = {}, headers = {}) {
        return requestFunc('patch', url, params, headers)
    },
    delete(url, params = {}, headers = {}) {
        return requestFunc('delete', url, params, headers)
    },
    //host,
}