<template>
    <div class="my-header" :class="{'my-header-active' : hideshow}">
        <div class="content">
            <el-image class="logo" :src="logo" fit="contain"></el-image>
            <div class="menu" v-for="(item, i) in titleList" :key="i" @click="onClick(item.url)">
                {{item.name}}
            </div>
            <!-- <div class="phone">
                <el-image class="tel" :src="tel" fit="contain"></el-image>
                <span>{{phone}}</span>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return{
            logo: require('@/assets/img/logo.png'),
            tel: require('@/assets/img/tel.png'),
            titleList: [
                {name: '首页', url: "/" }, 
                {name: '关于我们', url: "/about" }, 
                {name: '马场分布', url: "/horse" }, 
                {name: '新闻中心', url: "/news" }, 
                {name: '教练团队', url: "/coach" }, 
                {name: '考级认证', url: "" }, 
                {name: '联系我们', url: "/contact" }, 
            ],
            scroll: 0,
            phone: '',
            hideshow: false
        }
    },
    methods:{
        handleScroll() {
            let scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop;
            this.scroll = scrollTop
        },
        onClick(url) {
            if(url === '/about'){
                this.$router.push({path: url, query:{
                    name: '关于我们',
                    id: '5867f0545288ebdc0a5fc36c8f0dec23'
                }})
            }else{
                this.$router.push(url)
            }
        },
        getPhone() {
            this.$api.post('/sys/sysProp/sysProp-showForHomePage', {id: 'hotline'}).then(res=>{
                // console.log(res)
                this.phone = res.data.propertyValue
            })
        }
    },
    watch: {
        //监听显示高度
        scroll:function() {
            if(this.scroll === 0){
                //隐藏
                this.hideshow = false
            }else{
                //显示
                this.hideshow = true
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        this.getPhone()
    }
}
</script>

<style lang="scss">
  @import "~@/assets/css/var";
    .my-header{
        height: 80px;
        background-color: rgba(8, 7, 13, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        .content{
            width: 1200px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo{
                height: 55px;
                width: 200px;
            }
            .menu{
                // line-height:50px;
                font-size: 16px;
                padding: 10px 25px;
                &:hover{
                    color: #000;
                    background-color: #fff;
                    cursor: pointer;
                }
            }
            .phone{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-weight: bold;
                .tel{
                    height: 40px;
                    width: 40px;
                }
            }
           
        }
    }
    .my-header-active{
        background-color: $black;
    }
</style>