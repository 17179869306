<style lang="scss">
    @import "~@/assets/css/var";
    .my-footer {
        display: flex;
        justify-content:center;
        align-items: center;
        color: #fff;
        padding: 15px 0;
        border-top: 1px solid $border;
        background-color: $black;
        font-size: 14px;
        .contents{
            display: flex;
            justify-content:space-between;
            width: 1200px;
            padding: 30px 0;
            .line{
                line-height: 30px;
            }
            .left{
                .list{
                    display: flex;
                    .item{
                        padding-right: 50px;
                        .title{
                            color: #D3D3D3;
                            font-size: 16px;
                            font-weight: bold;
                        }
                        .line{
                            &:hover{
                                cursor: pointer;
                                color: $main;
                            }
                        }
                    }
                }
            }
            .center{
                .title{
                    color: #D3D3D3;
                    font-size: 16px;
                    font-weight: bold;
                }
                .tel{
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            .right{
                .el-image{
                    height: 150px;
                    width: 150px;
                }
            }

        }
       
    }
</style>
<script>
    export default {
        data() {
            return {
                list: [
                    {
                        name: '关于我们', 
                        child: [
                            {name: '企业简介'},
                            {name: '企业文化'},
                        ]
                    },
                    {
                        name: '马场分布', 
                        child: [ ]
                    },
                    {
                        name: '马术课程', 
                        child: []
                    },
                    {
                        name: '联系我们', 
                        child: [
                            {name: '联系方式'},
                        ]
                    },

                ],
                erweima: require('@/assets/img/erweima.jpg'),
                telAddress: {},
                page: {
                    current: 1,
                    size: 5,
                },
                model: {
                    status: 1, 
                    websiteFlag: 1
                },
                total: 0,
            }
        },
        created() {

        },
        methods: {
            goDetail(child, parent) {
                if(child.name === '企业简介'){
                    this.$router.push({path: '/about', query: {
                        id: '8e1e5d26772fc8158d151d6384c6e886',
                        name: child.name
                    }})
                }
                if(child.name === '企业文化'){
                    this.$router.push({path: '/about', query: {
                        id: '6635b912501a3ebc8a66763d6e96b416',
                        name: child.name
                    }})
                }
                if(child.name === '初级课程'){
                    this.$router.push({path: '/course', query: {
                        id: child.id,
                        type: 0
                    }})
                }
                if(child.name === '中级课程'){
                    this.$router.push({path: '/course', query: {
                        id: child.id,
                        type: 1
                    }})
                }
                if(child.name === '高级课程'){
                    this.$router.push({path: '/course', query: {
                        id: child.id,
                        type: 2
                    }})
                }
                if(child.name === '联系方式'){
                    this.$router.push({path: '/contact'})
                }
                if(parent.name === '马场分布'){
                    this.$router.push({path: '/horse/detail', query:{
                        id: child.id
                    }})
                }
            },
            getAddress() {
                this.$api.post('sys/sysProp/sysProp-findHomePageBaseInfo').then(res=>{
                    //console.log(res)
                    this.telAddress = res.data
                })
            },
            getHorseList() {
                this.$api.post('base/corp/corp-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                    //console.log(res)
                    let arr = []
                    res.data.records.forEach(ele=>{
                        arr.push({
                            name: ele.corpName,
                            id: ele.id
                        })
                    })
                    this.list.map(ele=>{
                        if(ele.name === '马场分布'){
                            ele.child = arr
                        }
                    })
                })
            },
            getCourseList() {
                this.$api.post('base/curriculum/curriculum-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                    //console.log(res)
                    let arr = []
                    res.data.records.forEach(ele=>{
                        arr.push({
                            name: ele.curriculumName,
                            id: ele.id
                        })
                    })
                    this.list.map(ele=>{
                        if(ele.name === '马术课程'){
                            ele.child = arr
                        }
                    })
                })
            }
        },
        mounted(){
            this.getAddress()
            this.getHorseList()
            this.getCourseList()
        }
    }
</script>
<template>
    <div class="my-footer">
        <div class="contents">
            <div class="left">
                <div class="list">
                    <div class="item" v-for="(parent, i) in list" :key="i">
                        <div class="title">{{parent.name}}</div>
                        <div v-for="(child, index) in parent.child" :key="index" class="line" @click="goDetail(child, parent)">{{child.name}}</div>
                    </div>
                </div>
            </div>
            <div class="center">
                <div class="title">联系方式</div>
                <div class="tel">{{telAddress.telephoneOne}}</div>
                <div class="tel">{{telAddress.telephoneTwo}}</div>
                <div class="line">公司地址：{{telAddress.companyAddress}}</div>
                <div class="line">公司邮箱：{{telAddress.companyEmail}}</div>
                <div class="line">公司网址：{{telAddress.companyWebsite}}</div>
            </div>
            <div class="right">
                <div class="line">微信扫描二维码</div>
                <div class="line">关注公众号</div>
                <el-image class="erweima" :src="erweima" fit="fill"></el-image>
            </div>

        </div>
    </div>
</template>