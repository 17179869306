<template>
    <div class="home">
        <div class="carousel">
            <el-carousel :interval="5000" arrow="always">
                <el-carousel-item v-for="(item, i) in imgList" :key="i">
                    <el-image class="logo" :src="item.url" fit="fill"></el-image>
                    <div class="desc" v-if="item.desc">
                        {{item.desc}}
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="home-content">
            <div class="content">
                <div class="desc">
                    <div class="left">
                        <div class="title">WELCOME TO</div>
                        <div class="com">江西红骑马术</div>
                        <div class="com">有限公司</div>
                    </div>
                    <div class="right">
                        <div class="title">
                            红骑马术 — 致力于推动 马术运动在中国的普及和发展！
                        </div>
                        <div class="text">
                            <p>
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               江西红骑马术俱乐部是一家致力于青少年马术培训和商务休闲骑行的马术运动连锁品牌，
                               目前位于南昌经开区的红骑马术学院于2022年4月试营业。旗舰店占地面积20余亩，
                               地理位置优越，毗邻红谷滩新区，交通方便，环境优美，场地设施目前处于江西领先水平，
                               我们从北京天津广州聘请了多名马术高级教练。同时俱乐部会所装修风格优雅，
                               让客户在动静结合享受马术贵族运动。同时公司以旗舰店为依托，在南昌高新、
                               朝阳、九龙湖筹建马术体验中心，在扬子洲已经申报红骑马主会员中心。同时赣州、
                               九江、吉安、上饶等地也在筹备马术俱乐部分部，计划于2023年建成吴城鄱阳湖草原马术越野基地，
                               让我们俱乐部的学员会员朋友可以在广袤的草原沙漠中策马扬鞭。
                            </p>
                        </div>
                        <div class="btn" @click="goAbout">
                            查看更多
                        </div>
                    </div>
                </div>
                <div class="horse">
                    <div class="title">
                        马场分布
                    </div>
                    <div class="horse-list">
                        <div class="item" v-for="item in horseList" :key="item.id" @click="goHorseDetail(item.id)">
                            <el-image class="horse-image" :src="getImg(item.thumbnailUrl)" fit="cover">
                                <el-image class="horse-image" slot="error" :src="horseUrl" fit="cover" />
                            </el-image>
                            <div class="info">
                                <div class="name">{{item.corpName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="btn">
                        <div class="button" @click="goHorse">查看更多</div>
                    </div>
                </div>
                <div class="wonderful">
                    <div class="title">
                        <div class="name">
                            赛事预告
                        </div>
                        <div class="tip">励志让每一个喜欢马和热爱马术运动的爱好者，提供专业化，职业化、规范化和系统化的马术训练。</div>
                    </div>
                    <div class="wonderful-content">
                        <div class="left" v-if="JSON.stringify(matchObj) !== '{}'">
                            <el-image class="logo" :src="getImg(matchObj.picUrl)" fit="cover"></el-image>
                            <div class="date">
                                <div class="year-month">
                                    <div class="year">
                                        {{matchObj.begDate | year}}
                                    </div>
                                    <div class="month">
                                        {{matchObj.begDate | month}}
                                    </div>
                                </div>
                                <div class="info" @click="goNewsDetail(matchObj.id)">
                                    <div class="info-title" >
                                        {{matchObj.matchName}}
                                    </div>
                                    <div class="text">
                                        <p>
                                            {{matchObj.corpName}}
                                        </p>
                                        <p>
                                            <i class="el-icon-location"></i>
                                            {{matchObj.address}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="date" v-for="item in matchList" :key="item.id">
                                <div class="year-month">
                                    <div class="year">
                                        {{item.begDate | year}}
                                    </div>
                                    <div class="month">
                                        {{item.begDate | month}}
                                    </div>
                                </div>
                                <div class="info" @click="goNewsDetail(item.id)">
                                    <div class="info-title">
                                        {{item.matchName}}
                                    </div>
                                    <div class="text">
                                        <p>
                                            {{item.corpName}}
                                        </p>
                                        <p>
                                            <i class="el-icon-location"></i>
                                            {{item.address}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="elegant">
                    <div class="elegant-horse">
                        <div class="title">
                            马场风采
                        </div>
                        <div class="text">
                            江西红骑马术下属有N家马术培训基地，室内，室外多块训练场地一应俱全。现有N多匹马，提供各类马术课程。致力于推动马术运动在中国的普及和发展！
                        </div>
                        <div class="btn" @click="goHorse">
                            查看更多
                        </div>
                    </div>
                    <div class="elegant-img" v-for="(item, i) in childList" :key="i">
                        <el-image class="child-image" :src="item.url" fit="fill"></el-image>
                    </div>
                </div>
                <div class="course">
                    <div class="title">
                        <div class="name">
                            马术课程
                        </div>
                        <div class="tip">
                            初学课程，是专门针对从来没有骑过马的人所设计的。
                            事实上，有些人虽然骑过马，往往仅止于在游乐场里兜圈子，或是没有人教自己乱骑，
                            或仅曾在郊区或去草原旅游时，跟着马队在野外走走而已。
                            每一个马术俱乐部都提供初学课程，教您正确的英式骑马方法与观念。
                        </div>
                    </div>
                    <div class="img-list">
                        <div class="item" v-for="(item, i) in courseList" :key="item.id">
                            <div class="img">
                                <el-image class="course-image" :src="item.url" fit="fill"></el-image>
                            </div>
                            <div class="info">
                                <div class="title">{{item.name}}</div>
                                <div class="english">{{item.english}}</div>
                                <div class="btn"><span @click="goCourse(i, item.id)">查看详情</span></div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="more-btn">
                        <span>查看更多</span>
                    </div> -->
                </div>
            </div>
        </div>
        <el-image class="bottom-img" :src="bottomImgUrl" fit="cover"></el-image>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: {
                current: 1,
                size: 4,
            },
            model: {
                status: 1, 
                websiteFlag: 1
            },
            //轮播图片
            imgList:[
                {url: require('@/assets/img/home/lunbo1.jpg'),},
                {url: require('@/assets/img/home/lunbo2.jpg'),},
                // {url: require('@/assets/img/home/lunbo3.jpg'),},
                // {url: require('@/assets/img/home/lunbo4.jpg'),},
                // {url: require('@/assets/img/home/lunbo5.jpg'),},
                // {url: require('@/assets/img/home/lunbo6.jpg'),},
                // {url: require('@/assets/img/home/lunbo7.jpg'),},
                // {url: require('@/assets/img/home/lunbo8.jpg'),},

            ],
            //默认图片
            horseUrl: require('@/assets/img/home/horse1.jpg'),
            horseList: [],
            //马场风采
            childList:[ 
                {url: require('@/assets/img/home/child1.jpg')},
                {url: require('@/assets/img/home/child2.jpg')},
                {url: require('@/assets/img/home/child3.jpg')},
            ],
            //精彩赛事
            matchList: [ ],
            matchObj: { },
            //马术课程
            courseList: [
                {url: require('@/assets/img/home/course1.jpg'), name: '初级课程', english: 'PRIMARY COURSE'},
                {url: require('@/assets/img/home/course2.jpg'), name: '中级课程', english: 'INTERMEDIATE COURSE'},
                {url: require('@/assets/img/home/course3.jpg'), name: '高级课程', english: 'ADVANCED COURSE'},
            ],
            wonderfulUrl: require('@/assets/img/home/lunbo2.jpg'),
            bottomImgUrl: require('@/assets/img/home/crBJ.jpg')

        }
    },
    filters: {
        year(val){
            let str = ''
            if(val){
                str = val.split('-')[0]
            }
            return str
        },
        month(val) {
            let str = ''
            if(val){
                str = val.split('-')[1] + '-' + val.split('-')[2]
            }
            return str
        }
    },
    methods: {
        goCourse(i, id) {
            this.$router.push({path: '/course', query: {
                type: i,
                id
            }})
        },
        goAbout() {
            this.$router.push({path: '/about'})
        },
        goHorse() {
            this.$router.push('/horse')
        },
        goHorseDetail(id) {
            this.$router.push({path: '/horse/detail', query:{
                id
            }})
        },
        getHorseList() {
            this.$api.post('base/corp/corp-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                this.horseList = res.data.records
            })
        },
        getCourseList() {
            this.$api.post('base/curriculum/curriculum-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                //console.log(res)
                res.data.records.forEach(parent=>{
                    this.courseList.map(child=>{
                        if(parent.curriculumName === child.name){
                            child.id = parent.id
                        }
                    })
                })
            })
        },
        //赛事预告
        getMatchList() {
            this.$api.post('base/match/match-noticeForHomePage', {page: this.page, model: this.model}).then(res=>{
                //console.log(res.data)
                res.data.records.forEach((ele, i)=>{
                    if(i === 0) {
                        this.matchObj = ele
                    }else{
                        this.matchList.push(ele)
                    }
                })
            })
        },
        goNewsDetail(id) {
            console.log(id)
            this.$router.push({
                path: '/news/detail',
                query: {id}
            })
        }
    },
    mounted() {
        this.getHorseList()
        this.getCourseList()
        this.getMatchList()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .home{
        .carousel{
            .el-carousel__item{
                .desc{
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: rgba(8, 7, 13, 0.5);
                    color: #fff;
                    padding: 50px;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .el-carousel__container{
                height: 750px;
                .el-image{
                    width: 100%;
                }
            }
            .el-carousel__indicators{
                display: none;
            }
        }
        &-content{
            display: flex;
            justify-content: center;
            .content{
                width: 1200px;
                margin-top: 80px;
                .desc{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .left{
                        div{
                            font-size: 38px;
                            font-weight: bold;
                            color: #000;
                        }
                        .title{
                            color: #808080;
                        }
                    }
                    .right{
                        width: 800px;
                        .title{
                            font-size: 20px;
                            color: #333;
                        }
                        .text{
                            font-size: 16px;
                            margin-top: 15px;
                            line-height: 40px;
                        }
                        .btn{
                            margin-top: 15px;
                            font-size: 14px;
                            padding: 5px 30px;
                            border: 1px solid #333;
                            width: 80px;
                            text-align: center; 
                            &:hover{
                                cursor: pointer;
                                border: 1px solid $main;
                                background-color: $main;
                                color: #fff;
                            }                                      
                        }
                    }
                    
                }
                .horse{
                    margin-top: 70px;
                   .title{
                        color: #333;
                        font-size: 32px;
                   }
                   &-list{
                       margin-top: 20px;
                       display: flex;
                       justify-content: space-between;
                        .item{
                            flex: 1;
                            margin: 0 10px;
                            position: relative;
                            .horse-image{
                                width: 100%;
                                height: 400px;
                                img{
                                    transition: all 0.3s;
                                    &:hover{
                                        transform: scale(1.1);
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                       .info{
                           position: absolute;
                           bottom: 0;
                           left: 0;
                           right: 0;
                           background-color: rgba(8, 7, 13, 0.7);
                           color: #fff;
                           padding: 20px;
                           cursor: pointer;
                           .name{
                               font-size: 20px;
                               font-weight: bold;
                               text-align: center;
                           }
                           .english{
                               font-size: 14px;
                               text-align: center;
                           }
                       }
                   }
                   .btn{
                       margin: 20px 0;
                       display: flex;
                       justify-content: flex-end;
                       .button{
                            font-size: 14px;
                            padding: 5px 30px;
                            border: 1px solid #333;
                            width: 80px;
                            text-align: center;  
                            &:hover{
                                cursor: pointer;
                                border: 1px solid $main;
                                background-color: $main;
                                color: #fff;
                            }                           
                        }
                   }
                }
                .wonderful{
                    .title{
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        .name{
                            font-size: 32px;
                            width: 400px;
                            padding: 8px 0;
                            border-bottom: 1px dashed #595959;
                        }
                        .tip{
                            margin-top: 15px;
                            font-size: 14px;
                            color: #595959;
                            width: 600px;
                        }
                    }
                    &-content{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 50px;
                        .left{
                            flex: 1;
                            .el-image{
                                width: 560px;
                                height: 320px;
                            }
                            .date{
                                color: #595959;
                                display: flex;
                                justify-content: space-between;
                                height: 120px;
                                padding: 20px 20px 5px;
                                &:hover{
                                    background-color: #F7F7F7;
                                    cursor: pointer;
                                }
                                .year-month{
                                    flex: 2;
                                    //width: 140px;
                                    text-align: center;
                                    .year{
                                        font-size: 24px;
                                    }
                                }
                                .info{
                                    flex: 10;
                                    padding-top: 5px;

                                    &-title{
                                        font-size: 16px;
                                        &:hover{
                                            color: #60a9d7;
                                            cursor: pointer;
                                        }
                                    }
                                    .text{
                                        padding-top: 5px;
                                        font-size: 14px;
                                        p{
                                            display: -webkit-box;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 3;
                                            overflow: hidden;
                                        };
                                    }
                                }

                               
                            }
                        }
                        .right{
                            flex: 1;
                            .date{
                                color: #595959;
                                display: flex;
                                justify-content: space-between;
                                height: 120px;
                                padding: 20px 20px 5px;
                                &:hover{
                                    background-color: #F7F7F7;
                                }
                                .year-month{
                                    flex: 2;
                                    //width: 140px;
                                    text-align: center;
                                    .year{
                                        font-size: 24px;
                                    }
                                }
                                .info{
                                    border-left: 1px solid #ededed;
                                    flex: 10;
                                    padding-top: 5px;
                                    padding-left: 20px;
                                    &-title{
                                        font-size: 16px;
                                        &:hover{
                                            color: #60a9d7;
                                        }
                                    }
                                    .text{
                                        padding-top: 5px;
                                        font-size: 14px;
                                        p{
                                            display: -webkit-box;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 3;
                                            overflow: hidden;
                                        };
                                    }
                                }

                               
                            }
                        }
                    }
                }
                .elegant{
                    display: flex;
                    margin-top: 50px;
                    margin-bottom: 30px;
                    &-horse, &-img{
                        flex: 1;
                    }
                    &-horse{
                        background-color: $black;
                        color: #fff;
                        .title{
                            font-size: 30px;
                            padding: 30px 30px 10px;
                        }
                        .text{
                            line-height: 30px;
                            font-size: 16px;
                            padding: 0 30px 10px;
                        }
                        .btn{
                            margin: 10px 30px 0;
                            font-size: 14px;
                            padding: 5px 30px;
                            border: 1px solid  #fff;
                            width: 80px;
                            text-align: center;   
                            &:hover {
                                background-color: $yellow;
                                border: 1px solid $yellow;
                                cursor: pointer;
                            }  
                        }

                    }
                }
                .course{
                    margin-top: 50px;
                    .title{
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        .name{
                            font-size: 32px;
                            width: 400px;
                            padding: 8px 0;
                            border-bottom: 1px dashed #595959;
                        }
                        .tip{
                            margin-top: 15px;
                            font-size: 14px;
                            color: #595959;
                            width: 600px;
                        }
                    }
                    .img-list{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 50px 0;
                        .item{
                            flex: 1;
                            margin: 0 15px;
                            position: relative;
                            cursor: pointer;
                            transition: all 0.3s;
                            &:hover{
                                transition: all 0.3s;
                                transform: scale(1.1);
                                // &:hover{
                                   
                                //     cursor: pointer;
                                // }
                                // transform: scale(1.1);
                                // transition: all 0.3s;
                                // cursor: pointer;
                            }
                            
                            .course-image{
                                width: 100%;
                                height: 450px;
                            }
                            .info{
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0 ,0, .5);
                                color: #fff;
                                .title{
                                    font-size: 28px;
                                    //font-weight: bold;
                                    padding-top: 220px;
                                    text-align: center;
                                }
                                .english{
                                    text-align: center;
                                    font-size: 14px;
                                }
                                .btn{
                                    margin-top: 30px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    span{
                                        display: inline-block;
                                        padding: 10px 25px;
                                        border: 1px solid #fff;
                                        font-size: 14px;
                                        &:hover {
                                            background-color: $yellow;
                                            border: 1px solid $yellow;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }

                    }
                    .more-btn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 50px;
                        span{
                            display: inline-block;
                            padding: 10px 25px;
                            border: 1px solid;
                            font-size: 14px;
                            cursor: pointer;
                        }
                       
                        
                    }
                }
            }
        }
        .bottom-img{
            height: 500px;
            width: 100%;
        }
        
    }

</style>