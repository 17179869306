import variable from './variable';
import router from '@/config/router'


// region 日期格式化
function timeFormat(time, fmt) {
    const o = {
        "M+": time.getMonth() + 1, //月份
        "d+": time.getDate(), //日
        "h+": time.getHours(), //小时
        "m+": time.getMinutes(), //分
        "s+": time.getSeconds(), //秒
        "q+": Math.floor((time.getMonth() + 3) / 3), //季度
        "S": time.getMilliseconds() //毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt || '1970-01-01 00:00:00'
}
Date.prototype.Format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
    return timeFormat(this, fmt)
}
Date.prototype.FormatShort = function () {
    return timeFormat(this, 'yyyy-MM-dd')
}
String.prototype.Format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
    if(isNaN(this) && !isNaN(Date.parse(this))){
        return timeFormat(new Date(this), fmt)
    }
}
String.prototype.FormatShort = function () {
    if(isNaN(this) && !isNaN(Date.parse(this))){
        return timeFormat(new Date(this), 'yyyy-MM-dd')
    }
}
const connectKey = '*#06#';
function getRealName(name) {
    if (name.indexOf(connectKey) !== -1) {
        return name.split(connectKey)[0]
    }
    return name
}

const colors = ['#5171DF', '#DC4D4D', '#7CC684', '#3CB7DB', '#928CF9', '#E796FF', '#C0D97F', '#AA8073']
// endregion
// 深拷贝
const clone = (obj) => {
    let o
    if (typeof obj === 'object') {
        if (obj === null) {
            o = null;
        } else {
            if (obj instanceof Array) {
                o = [];
                for (let i = 0, len = obj.length; i < len; i++) {
                    o.push(clone(obj[i]));
                }
            } else if (obj instanceof Date) {
                o = new Date(obj)
            } else {
                o = {};
                for (let j in obj) {
                    o[j] = clone(obj[j]);
                }
            }
        }
    } else {
        o = obj;
    }
    return o;
}

const store = {
    get(key) {
        const value = localStorage.getItem(key)
        if (value) {
            try {
                const value_json = JSON.parse(value)
                if (typeof value_json === 'object') {
                    return value_json
                } else if (typeof value_json === 'number') {
                    return value_json
                }
            } catch(e) {
                return value
            }
        } else {
            return false
        }
    },
    set(key, value) {
        localStorage.setItem(key, value)
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear();
    }
}

export default {
    // 深拷贝
    deepClone: clone,

    // 合并参数
    joinObj() {
        const obj = {}
        for (let i = 0; i < arguments.length; i++) {
            Object.assign(obj, arguments[i])
        }
        return obj
    },

    // 翻页通用参数
    getPage() {
        return {
            page: 1,
            pageSize: 20,
            // sidx: '',
            // sord: '',
            total: 0,
        }
    },

    // storage操作
    store,
    token: {
        get() {
            return store.get('taoshuToken')
        },
        set(val) {
            store.set('taoshuToken', val)
        },
        remove() {
            store.remove('taoshuToken')
        },
    },

    goLogin() {
        router.push(`/login?url=${encodeURIComponent(window.location.href)}`)
    },

    // 首页通用表格的获取列
    getCommonTable(param) {
        param.type = 'table';
        param.list = [];
        param.loading = false;
        param.pageParam = {
            page: 1,
            pageSize: variable.basePageSize,
            total: 0,
        };
        param.error = ''
        param.empty = false
        param.sortParam = {};
        param.cols = param.cols || [];
        param.cols = param.cols.map(item => {
            !item.list_view && (item.list_view = '是');
            return item
        })
        return param;
    },

    renderBar(el, amount, rate, isLong, isCrude) {
        if (isCrude === undefined) {
            isCrude = !isLong
        }


        // region 开始组装数据

        let x = []        // x轴的数据

        let amountY = []  // 柱状图的y轴
        let amountYMax = 100

        let rateY = []    // 折线图的y轴
        let rateYMin = 0
        let rateYMax = 0

        for (let key in amount) {
            const temp = parseInt(key)
            if (!isNaN(temp)) {
                x.push(key)
                amountY.push(parseFloat(amount[key]))
                amountYMax = Math.max(amountYMax, parseFloat(amount[key]))


                // 处理第二列
                const val = rate[key]
                if (val || val === 0) {
                    rateY.push(parseFloat(val))
                    rateYMin = Math.min(rateYMin, parseFloat(val))
                    rateYMax = Math.max(rateYMax, parseFloat(val))
                } else {
                    rateY.push('')
                }

            }
        }
        rateYMin = rateYMin < 0 ? Math.min(-100, Math.floor(rateYMin)) : 0
        // rateYMax = rateYMax < 0 ? 0 : Math.max(100, Math.ceil(rateYMax))
        rateYMax = rateYMax < 0 ? 0 : (rateYMax - (rateYMax % 5) + 5)

        // 保持金额的最大值能被5整除
        amountYMax = amountYMax - (amountYMax % 5) + 5

        // endregion



        const myChart = window.echarts.init(el)
        myChart.setOption({
            // width: isLong ? undefined : '50%',
            color: colors,
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    return `${params[0].axisValue}<br/>${params[0].seriesName}：${params[0].value}<br/>${params[1].seriesName}：${params[1].value}%`;
                },
                axisPointer: {
                    animation: false
                }
            },
            grid: isLong ? undefined : {
                left: '25%',
                right: '25%'
            },
            legend: {
                data:[amount.name, rate.name]
            },
            xAxis: [
                {
                    type: 'category',
                    data: x,
                    // axisLabel: isLong ? {
                    //     interval:0,
                    //     rotate:40
                    // } : undefined
                },
                {
                    type: 'category',
                    data: x,
                    show: false,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    // name: '销售额',
                    max: amountYMax,
                    min: 0,
                    splitNumber: 5,
                    interval: amountYMax / 5,
                },
                {
                    type: 'value',
                    scale: true,
                    // name: '增长比',
                    max: rateYMax,
                    min: rateYMin,
                    splitNumber: 5,
                    interval: (rateYMax - rateYMin) / 5,
                    axisLabel:{
                        formatter: v => `${v}%`
                    }
                },
            ],
            series: [
                {
                    name: amount.name,
                    type: 'bar',
                    barWidth: isCrude ? 24 : 8,
                    itemStyle: {
                        barBorderRadius: isCrude ? 12 : 8,
                    },
                    data: amountY,
                },
                {
                    name: rate.name,
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    data: rateY,
                },
            ],
        }, true)

        return myChart
    },
    renderPie(el, data, hideName, startAngle = 90) {
        const myChart = window.echarts.init(el)
        myChart.setOption({
            color: colors,
            tooltip: {
                trigger: 'item',
                formatter: "{b}: {c} ({d}%)"
            },
            // legend: {
            //     type: 'scroll',
            //     orient: 'vertical',
            //     right: 10,
            //     top: 20,
            //     bottom: 20,
            //     data: data.map(item => item.name)
            // },
            series: [
                {
                    name: '销量占比',
                    type: 'pie',
                    radius: '55%',
                    label: {
                        show: !!hideName,
                        position: 'outer',
                        margin: 5,
                        fontSize: 11,
                        formatter: '\n{b}：{d}%',
                    },
                    startAngle,
                    itemStyle: {
                        normal: {
                            opacity: 1,
                            borderWidth: 2,
                            borderColor: '#fff',
                        }
                    },
                    data: data
                }
            ]
        }, true)
    },
    renderDoublePie(el1, el2, data, hideName) {
        if (data.length > 15) {
            el2.style.display = 'block'

            var total = 0
            data.forEach(item => {
                total += item.value
            })

            // 前10条放到第一个图里
            let firstList = data.splice(0, 10)

            // 设置其他
            const other = {name: '其他', value: 0}
            data.forEach(item => {
                other.value += item.value
            })

            // 把这个其他方法第一个列数的首项
            firstList = [other].concat(firstList)
            this.renderPie(el1, firstList, hideName, other.value / total * 180)



            // 处理第二个图表
            const secondList = data.length > 10 ? data.splice(0, 10) : data
            this.renderPie(el2, secondList, hideName)

        } else {
            el2.style.display = 'none'
            this.renderPie(el1, data, hideName)
        }
    },
    renderChina (el, data, handle, title) {
        const yData = [];

        data.sort(function(o1, o2) {
            if (isNaN(o1.value) || o1.value == null) return -1;
            if (isNaN(o2.value) || o2.value == null) return 1;
            return o1.value - o2.value;
        });

        let max = 100
        for (let i = 0; i < data.length; i++) {
            yData.push(data[i].name);

            // 获取最大值
            max = Math.max(max, parseFloat(data[i].value))
        }

        var option = {
            title: {
                text: title,
                x: 'center'
            },
            tooltip: {
                show: true,
                formatter: function(params) {
                    return params.name + '：' + params.data['value']
                },
            },
            visualMap: {
                type: 'continuous',
                text: ['', ''],
                showLabel: true,
                seriesIndex: [0],
                min: 0,
                max: max,
                inRange: {
                    color: ['#edfbfb', '#b7d6f3', '#40a9ed', '#3598c1', '#215096', ]
                },
                textStyle: {
                    color: '#000'
                },
                bottom: 30,
                left: 'left',
            },
            grid: {
                right: 10,
                top: 80,
                bottom: 30,
                width: '20%'
            },
            xAxis: {
                type: 'value',
                scale: true,
                position: 'top',
                splitNumber: 1,
                boundaryGap: false,
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    margin: 2,
                    textStyle: {
                        color: '#aaa'
                    }
                }
            },
            yAxis: {
                type: 'category',
                nameGap: 16,
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#ddd'
                    }
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: '#ddd'
                    }
                },
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        color: '#999'
                    }
                },
                data: yData
            },
            geo: {
                roam: 'move',
                map: 'china',
                left: 'left',
                right: '300',
                zoom: 0.7,
                layoutSize: '80%',
                label: {
                    normal: {
                        show: false
                    },
                    emphasis: {
                        show: true
                    }
                },
                itemStyle: {
                    emphasis: {
                        areaColor: '#fff464'
                    }
                },
            },
            series: [{
                name: '销量',
                type: 'map',
                roam: false,
                geoIndex: 0,
                label: {
                    show: false,
                },
                data: data
            }, {
                name: 'barSer',
                type: 'bar',
                roam: false,
                visualMap: false,
                zlevel: 2,
                barMaxWidth: 20,
                itemStyle: {
                    normal: {
                        color: '#40a9ed'
                    },
                    emphasis: {
                        color: "#3596c0"
                    }
                },
                label: {
                    normal: {
                        show: false,
                        position: 'right',
                        offset: [0, 10]
                    },
                    emphasis: {
                        show: true,
                        position: 'right',
                        offset: [10, 0]
                    }
                },
                data: data
            }]
        };
        const myChart = window.echarts.init(el);
        if (handle) {
            myChart.off('click')
            myChart.on('click', function (param) {
                handle(param)
            })
        }
        myChart.setOption(option)
    },


    yearIsEmpty(obj) {
        for (let i = 0; i < variable.newYears.length; i += 1) {
            if (obj[variable.newYears[i]] !== 0)  {
                return false
            }
        }
        return true
    },

    goIndexDrug(drug) {
        const routeUrl = router.resolve({
            path: 'drug',
            query:{
                txt: drug
            }
        })
        window.open(routeUrl.href, '_blank');
    },
    goIndexCorp(corp) {
        const routeUrl = router.resolve({
            path: 'corp',
            query:{
                txt: corp
            }
        })
        window.open(routeUrl.href, '_blank');
    },
    getCanvasSeries(canvasData) {
        const color1 = '#006acc';
        const color2 = '#ff7d18';
        const color3 = '#10a050';

        let categories = [
            {
                name: '公司',
                itemStyle: {
                    color: color1
                }
            },
            {
                name: '董事',
                itemStyle: {
                    color: color2
                }
            },
            {
                name: '董事1',
                itemStyle: {
                    color: color3
                }
            }
        ];
        return [{
            type: 'graph',
            layout: 'force',
            symbolSize: 58,
            draggable: true,
            roam: 'move',
            focusNodeAdjacency: false,
            categories: categories,
            repulsion: 10,
            edgeSymbol: ['', 'arrow'],
            // edgeSymbolSize: [80, 10],
            edgeLabel: {
                normal: {
                    show: false,
                }
            },
            label: {
                show: true,
                formatter(x){
                    return `${getRealName(x.data.name)}${x.data.qty ? '/' + x.data.qty : ''}`
                },
                color: '#000000',
                backgroundColor: '#e2f0da',
                padding: [5, 10],
            },
            force: {
                repulsion: 800,
                edgeLength: 80
            },
            data: canvasData.nodes,
            links: canvasData.links
        }]
    }
}